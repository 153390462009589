import MainWrapper from "./MainWrapper";


function App() {
  return (
    <MainWrapper />
  );
}

export default App;
